import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

export default function Home() {
  return (
    <Layout>

      <Helmet>
          <meta charSet="utf-8" />
          <title>A Software Development Studio | CheePlus</title>
          <link rel="canonical" href="https://www.cheeplus.com/" />
      </Helmet>


      <h1>Welcome</h1>
      <p>
        CheePlus is a software development studio.
      </p>
    </Layout>
  );
}